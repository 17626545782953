<template>
  <div class="sign-area">
    <!-- 1단계에서만 타이틀을 표시함 -->
    <div
      v-if="this.currentStep === 1"
      class="title-wrap">
      <!-- 회원가입 -->
      <h2
        :class="{ kor: $i18n.locale === 'ko' }"
      >{{ $t('content.auth.SignUp.Title') }}</h2>

      <!-- 드림플러스는 SNS로만 회원가입 하실 수 있습니다. -->
      <div class="title-desc">{{ $t('content.auth.SignUp.TitleDesc') }}</div>
    </div>

    <!-- 1단계에서는 스텝 자체를 표시하지 않음 -->
    <div
      v-if="this.currentStep !== 1"
      class="step-wrap"
    >
      <ul class="step">
        <!-- 01 가입 유형 선택 -->
        <li
          :class="{ on: this.currentStep === 1 }"
        >{{$t("signUp.title.type")}}</li>

        <!-- 02 정보 입력 -->
        <li
          :class="{ on: this.currentStep === 2 }"
        >{{$t("signUp.title.user")}}</li>

        <!-- 03 약관동의 -->
        <li
          :class="{ on: this.currentStep === 3 }"
        >{{$t("signUp.title.agreement")}}</li>

        <!-- 04 가입 완료 -->
        <li
          :class="{ on: this.currentStep === 4 }"
        >{{$t("signUp.title.auth")}}</li>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'SignUp',
  computed: {
    /**
     * 현재 스텝
     */
    currentStep() {
      const { path } = this.$route;
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match
      return Number(path.match(/\/user\/signup\/step(\d+)/)[1]);
    },
  },
};
</script>
